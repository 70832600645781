<template>
  <v-select
    :items="times"
    :value="iTime"
    item-text="text"
    item-value="value"
    :label="label || 'Time'"
    :disabled="disabled"
    persistent-hint
    color="color3"
    item-color="color3"
    :hide-details="hideDetails"
  ></v-select>
</template>

<script>
import TimeOptions from '@/helpers/TimeOptions.js'

export default {
  props: ['time', 'label', 'disabled', 'hideDetails'],
  data () {
    return {
      x: null
    }
  },
  computed: {
    iTime: {
      get () {
        return this.time
      },
      set (val) {
        this.$emit('update:time', val)
      }
    },
    times () {
      return TimeOptions
    }
  }
}
</script>
