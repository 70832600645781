let i = 0
const result = []
const min = [':00', ':15', ':30', ':45']
for (i; i < 24; i++) {
  const ap = i > 11 ? 'PM' : 'AM'
  const h = i === 0 ? 12 : i > 11 ? i - 12 : i
  const iPad = `${i}`.padStart(2, 0)
  min.forEach(m => {
    result.push({ text: `${h}${m} ${ap}`, value: `${iPad}${m}` })
  })
}

export default result
