<template>
  <v-container fluid class="pa-0" v-if="repeatSettings">
    <v-row dense>
      <v-col cols="8" md="4" class="mb-3">
        <v-menu
          v-model="startMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :disabled="disabled"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="displayDate"
              label="Date"
              prepend-icon="fas fa-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :disabled="disabled"
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            @input="startMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <time-selector
          :time.sync="startTime"
          :disabled="disabled"
          :hideDetails="true"
        ></time-selector>
      </v-col>
      <v-col md="4" class="d-flex align-center">
        <v-switch
          label="Repeat"
          v-model="repeatSettings.enabled"
          color="success"
          :disabled="disabled"
          @focus="onFocus"
          @blur="onBlur"
          hide-details
          class="mt-0"
        ></v-switch>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-row dense v-if="repeatSettings.enabled">
        <v-col cols="12">
          <v-card :flat="!focused">
            <v-card-text class="pa-0">
              <v-row dense>
                <v-col class="align-center d-flex" cols="auto">Repeat Every</v-col>
                <v-col cols="2" sm="1" class="align-center d-flex">
                  <v-text-field
                    type="number"
                    color="color3"
                    :disabled="disabled"
                    v-model="repeatSettings.n"
                    @focus="onFocus"
                    @blur="onBlur"
                    filled dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="align-center d-flex">
                  <v-select
                    :items="repeatOptions"
                    color="color3"
                    item-color="color3"
                    v-model="repeatSettings.freq"
                    @focus="onFocus"
                    @blur="onBlur"
                    filled dense
                    hide-details
                    :disabled="disabled"
                  ></v-select>
                </v-col>
              </v-row>
              <v-expand-transition>
                <v-row dense v-if="repeatSettings.freq === 'w'">
                  <v-col class="align-center d-flex" cols="12">Repeat On</v-col>
                  <v-col cols="12" class="text-center">
                    <v-chip-group
                      multiple
                      active-class="color3 color3Text--text"
                      v-model="repeatSettings.days"
                    >
                      <v-chip :disabled="disabled">S</v-chip>
                      <v-chip :disabled="disabled">M</v-chip>
                      <v-chip :disabled="disabled">T</v-chip>
                      <v-chip :disabled="disabled">W</v-chip>
                      <v-chip :disabled="disabled">T</v-chip>
                      <v-chip :disabled="disabled">F</v-chip>
                      <v-chip :disabled="disabled">S</v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
              </v-expand-transition>
              <v-row dense>
                <v-col class="align-center d-flex" cols="12">Ends</v-col>
                <v-col cols="12">
                  <v-radio-group v-model="repeatSettings.endType" :disabled="disabled" class="mt-0">
                    <v-row dense>
                      <v-col cols="3" md="2" class="d-flex align-center">
                        <v-radio label="On" value="on" color="success"></v-radio>
                      </v-col>
                      <v-col cols="9" md="4" class="d-flex align-center">
                        <v-menu
                          v-model="endMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          :disabled="disabled"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="displayEnd"
                              label="Date"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :disabled="disabled || repeatSettings.endType !== 'on'"
                              hide-details
                              class="pt-0"
                              filled
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="end"
                            @input="startMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="3" md="2" class="d-flex align-center">
                        <v-radio label="After" value="after" color="success"></v-radio>
                      </v-col>
                      <v-col cols="9" md="4" class="d-flex align-center">
                        <v-text-field
                          v-model="repeatSettings.end"
                          suffix=" Occurences"
                          type="number"
                          color="color3"
                          hide-details
                          class="pt-0"
                          filled
                          :disabled="disabled || repeatSettings.endType !== 'after'"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-expand-transition>
  </v-container>
</template>

<script>
import TimeSelector from '@/New/TimeSelector'
import moment from 'moment'

export default {
  props: ['disabled', 'tournament', 'startDate', 'startTime', 'endDate', 'settings'],
  data () {
    return {
      startMenu: false,
      endMenu: false,
      repeat: false,
      repeatSettings: null,
      focus: [],
      scheduledEvents: []
    }
  },
  computed: {
    occurOpt () {
      return Array.from({ length: 999 }, (v, i) => { return { text: `${i + 1} Occurences`, value: i + 1 } })
    },
    displayDate () {
      return moment(this.date).format('dddd, MMMM D')
    },
    displayEnd () {
      return moment(this.end).format('dddd, MMMM D, yyyy')
    },
    repeatOptions () {
      const s = this.repeatSettings.n > 1 ? 's' : ''
      return [
        { text: `day${s}`, value: 'd' },
        { text: `week${s}`, value: 'w' },
        { text: `month${s}`, value: 'm' },
        { text: `year${s}`, value: 'y' }
      ]
    },
    date: {
      get () {
        const m = moment.isMoment(this.startDate) ? this.startDate : moment(this.startDate)
        return m.format('YYYY-MM-DD')
      },
      set (val) {
        this.$emit('update:startDate', val)
      }
    },
    end: {
      get () {
        const m = moment.isMoment(this.endDate) ? this.endDate : moment(this.endDate)
        return m.format('YYYY-MM-DD')
      },
      set (val) {
        this.$emit('update:endDate', val)
      }
    },
    focused () {
      return this.focus.length > 0
    }
  },
  methods: {
    init () {
      if (this.settings) {
        this.repeatSettings = this.settings
      } else {
        this.repeatSettings = {
          n: 1,
          freq: 'w',
          days: [+moment(this.startDate).format('d')],
          end: 13,
          endType: 'on'
        }
      }
    },
    onFocus (e) {
      this.focus.push(e.srcElement.id)
    },
    onBlur (e) {
      this.focus = this.focus.filter(f => f !== e.srcElement.id)
    },
    scheduleEvent () {
      const startDate = new Date(this.startDate)
      const endDate = new Date(this.endDate)
      const events = []
      const f = this.repeatSettings.freq
      const et = this.repeatSettings.endType
      const e = this.repeatSettings.end
      let i = 0

      if (f === 'd') {
        const oneDay = 24 * 60 * 60 * 1000
        let currentDate = startDate

        while (et === 'on' ? currentDate <= endDate : i < e) {
          events.push(currentDate.toJSON())
          currentDate = new Date(currentDate.getTime() + oneDay)
          i++
        }
      } else if (f === 'w') {
        const oneDay = 24 * 60 * 60 * 1000
        let currentDate = startDate

        while (et === 'on' ? currentDate <= endDate : i < e) {
          if (this.repeatSettings.days.includes(currentDate.getDay())) {
            events.push(currentDate.toJSON())
            i++
          }
          currentDate = new Date(currentDate.getTime() + oneDay)
        }
      } else if (f === 'm') {
        const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())

        // eslint-disable-next-line no-unmodified-loop-condition
        while (et === 'on' ? currentDate <= endDate : i < e) {
          events.push(currentDate.toJSON())
          currentDate.setMonth(currentDate.getMonth() + 1)
          i++
        }
      }

      console.log(events)
      this.scheduledEvents = events
      this.$emit('update:settings', this.repeatSettings)
    }
  },
  watch: {
    'repeatSettings.enabled': function (val) {
      const s = moment(this.date)
      if (val) {
        this.end = s.add(13, 'w').format('YYYY-MM-DD')
      } else {
        this.end = s.format('YYYY-MM-DD')
      }
    },
    repeatSettings: {
      handler: function (v) {
        this.scheduleEvent()
      },
      deep: true
    },
    end: 'scheduleEvent',
    date: 'scheduleEvent'
  },
  mounted () {
    this.init()
  },
  components: {
    TimeSelector
  }
}
</script>

<style lang="scss" scoped>

</style>
